.Navbar {
    background-color: #D9D9D9;
    padding: 1.5%;
    font-size: 20pt;
    display: flex;
    justify-content: space-around;
}

.Navbar .header {
    font-weight: 900;
    color: #861F41;
    text-transform: uppercase;
}

.Navbar span span {
    margin: auto 1rem;
}

.matches{
    margin-top: 3%;
}

.matches div {
    margin: 1% auto
}